/* eslint-disable import/no-anonymous-default-export */
import { configureStore } from '@reduxjs/toolkit';

import { IStateHeader } from './reducers/headerReducer';
import { IStateAdvice } from './reducers/adviceReducer';
import { IStateUser } from './reducers/user/userReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';
import { loadUserData } from './reducers/user/actions';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export interface IRootState {
  header: IStateHeader;
  advice: IStateAdvice;
  user: IStateUser;
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const state = store.getState();
if (!state.user || !state.user.userId) {
  store.dispatch(loadUserData())
}

export default store;
