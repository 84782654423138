import { IIconProps } from '../interface';

const StarIcon: React.FC<IIconProps> = ({
  width,
  height,
  color = '#FFD700',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill={color}
      stroke="orange"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 15 
           Q55 25 61 39 
           L87 42 
           Q80 50 67 60 
           L72 86 
           Q60 75 50 72 
           Q40 75 28 86 
           L33 60 
           Q20 50 13 42 
           L39 39 
           Q45 25 50 15 
           Z"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;