import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import checkSession from '../../utils/checkSession';
import UserService from '../../services/user';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import store, { IRootState } from '../../store';
import { loadUserData } from '../../reducers/user/actions';

const CheckSession = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: IRootState) => state.user);
  const handleCheckUserData = async (userId: string) => {
    const { data: sessionData } = await supabase.auth.getSession();
    const adviceServices = new UserService(sessionData.session);
    const { data } = await adviceServices.checkData(userId);
    return data;
  };

  const { mutate: checkUserData } = useMutation(
    handleCheckUserData,
  );

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      const currentUser = sessionData?.data.session?.user;

      if (currentUser) {
        if (await checkSession(currentUser)) {
          navigate('/');
        }

        return;
      }
    };

    handleSession();
  }, [location, navigate]);

  useEffect(() => {
    const handleSession = async () => {
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (_, session) => {
          const currentUser = session?.user;

          if (session?.user?.id) {
            checkUserData(session?.user?.id);
          }

          if (!user.userId || !user.firstName || !user.lastName) {
            store.dispatch(loadUserData());
          }

          if (await checkSession(currentUser)) {
            navigate('/');
          }
        }
      );

      return () => {
        authListener.subscription.unsubscribe();
      };
    };

    handleSession();
  }, [checkUserData, navigate, user]);

  return <Outlet />;
};

export default CheckSession;
