// src/redux/actions.ts
import { addBreadcrumb } from '@sentry/react';
import UserService from '../../services/user';
import { supabase } from '../../supabaseClient';
import { AppsCategoryEnum, Level } from '../../utils/sentry';
import { SET_USER_DATA, CLEAR_USER_ID } from './../actionTypes';
import { Dispatch } from 'redux';

export interface SetUserIdAction {
  type: typeof SET_USER_DATA;
  payload: {
    userId: string;
    firstName?: string;
    lastName?: string;
  };
}

export interface ClearUserIdAction {
  type: typeof CLEAR_USER_ID;
}

export type UserActionTypes = SetUserIdAction | ClearUserIdAction;

export const setUserId = ({id, firstName, lastName}: {id: string, firstName?: string; lastName?: string}): SetUserIdAction => ({
  type: SET_USER_DATA,
  payload: {
    userId: id,
    firstName,
    lastName
  },
});

export const loadUserData = () => async (dispatch: Dispatch<UserActionTypes>) => {
  try {
    const { data: sessionData } = await supabase.auth.getSession();
    const userService = new UserService(sessionData.session);

    const {data: userProfile} = await userService.getProfile()
    if (userProfile) {
      dispatch({ type: 'SET_USER_DATA', payload: {
        userId: userProfile.id,
        firstName: userProfile.first_name,
        lastName: userProfile.last_name
      } });
    }
  } catch (error) {
    addBreadcrumb({
      category: AppsCategoryEnum.APP,
      message: 'Error loading user data',
      level: Level.Error,
      data: { error },
    });
  }
};

export const clearUserId = (): ClearUserIdAction => ({
  type: CLEAR_USER_ID,
});
