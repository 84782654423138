// src/redux/userReducer.ts
import { SET_USER_DATA, CLEAR_USER_ID } from './../actionTypes';
import { UserActionTypes } from './actions';

export interface IStateUser {
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
}

const initialState: IStateUser = {
  userId: null,
  firstName: null,
  lastName: null
};

const userReducer = (state = initialState, action: UserActionTypes): IStateUser => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userId: action.payload.userId,
        firstName: action.payload.firstName || null,
        lastName: action.payload.lastName || null
      };
    case CLEAR_USER_ID:
      return {
        ...state,
        userId: null,
      };
    default:
      return state;
  }
};

export default userReducer;
