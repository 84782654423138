import AxiosClass from '.';
import env from '../env';

class UserService extends AxiosClass {
  async getUserRole() {
    return this.axiosInstance.get(`${env.APP_API}/user/roles`);
  }

  async getUserDetail() {
    return this.axiosInstance.get(`${env.APP_API}/user/details`);
  }

  async registerUser(payload: any) {
    return this.axiosInstance.post(`${env.APP_API}/auth/register`, payload);
  }

  async sendMessage(payload: any) {
    return this.axiosInstance.post(`${env.APP_API}/auth/send-message`, payload);
  }

  async updateUser(payload: any) {
    return this.axiosInstance.patch(`${env.APP_API}/client/profile`, payload);
  }

  async getProfile() {
    return this.axiosInstance.get(`${env.APP_API}/client/profile`);
  }

  async checkData(userId: string): Promise<{
    data: any
  }> {
    return this.axiosInstance.post(`${env.APP_API}/webhook/checkup/users/${userId}`);
  }
}

export default UserService;
